.container {
  display: flex;
}

.loginButton {
  display: none;
}

.loginModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--cui-spacings-mega);
  margin: var(--cui-spacings-mega) var(--cui-spacings-kilo);
}

.loginModalIcon {
  background: var(--cui-bg-subtle);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile {
  display: flex;
  cursor: pointer;
}

.profileAndSearchWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.searchIconWrapper {
  display: flex;
  align-items: center;
  padding: 0 var(--cui-spacings-mega) 0 var(--cui-spacings-mega);
}

/* Mobile styles */
@media screen and (max-width: 480px) {
  .loginButton {
    display: none;
  }

  .popover>*>button:is(:last-child) {
    margin-bottom: var(--cui-spacings-giga);
  }

  .popoverWrapper {
    display: flex;
  }

  .searchIconWrapper {
    display: flex;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .loginButton {
    display: none;
  }

  .popover {
    margin-bottom: var(--cui-spacings-mega);
  }

  .popover>*>button:is(:last-child) {
    margin-bottom: var(--cui-spacings-giga);
  }

  .popoverWrapper {
    display: flex;
  }

  .searchIconWrapper {
    display: flex;
  }
}


/* Tablet styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .hamburger {
    display: none;
  }

  .loginButton {
    display: flex;
  }

  .searchIconWrapper {
    display: none;
  }
}

/* Desktop styles */
@media screen and (min-width: 1280px) and (max-width: 1780px) {
  .hamburger {
    display: none;
  }

  .loginButton {
    display: flex;
  }

  .searchIconWrapper {
    display: none;
  }
}

@media screen and (min-width: 1780px) {
  .hamburger {
    display: none;
  }

  .loginButton {
    display: flex;
  }

  .searchIconWrapper {
    display: none;
  }
}
/* Mobile styles */
.topnavContainer {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  align-items: center;
  width: auto;
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .topnavContainer {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .topnavContainer {
    display: flex;
    padding: 8px 32px;
    justify-content: space-between;
  }
}

/* Desktop styles */
@media screen and (min-width: 1280px) and (max-width: 1760px) {
  .topnavContainer {
    display: flex;
    justify-content: space-between;
    padding: 8px 32px;
  }
}

@media screen and (min-width: 1760px) {
  .topnavContainer {
    display: flex;
    padding: 8px 32px;
    justify-content: space-between;
  }
}
.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: var(--cui-bg-normal);
  z-index: 1000;
  display: block;
  padding:  var(--cui-spacings-kilo)  var(--cui-spacings-giga);
  overflow: scroll;
}

.none {
  display: none;
}

.search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--cui-border-radius-byte);
  background: var(--cui-bg-normal);
  width: 100%;
  max-width: 538px;
  z-index: 1000;
}

.search > input {
  margin-right: 4px;
  z-index: 1000;
}

.inputSearch {
  width: 100%;
  max-width: 538px;
  z-index: 10000;
}

.searchOverlay {
  top: 200px;
  display: flex;
  justify-content: center;
  gap: var(--cui-spacings-byte);
  z-index: 1000;
  margin-bottom: var(--cui-spacings-mega);
}

.searchResultsContainer {
  display: block;
  position: relative;
  background-color: var(--cui-bg-normal);
  right: 0;
  top: 8px;
  z-index: 1000;
  height: auto;
  border-radius: var(--cui-border-radius-mega);
}

.seeAllResultsButtonWrapper {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.closeContainer {
  display: block;
  z-index: 1001;
  padding: var(--cui-spacings-kilo);
  border-radius: var(--cui-border-radius-kilo);
  border: var(--cui-border-width-kilo) solid var(--cui-border-normal);
}

.close {
  display: flex;
}

@media screen and (min-width: 480px) {
  .overlay {
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background-color: var(--cui-bg-normal);
    z-index: 1000;
    display: block;
    padding:  var(--cui-spacings-kilo)  var(--cui-spacings-kilo);
  }
  
  .none {
    display: none;
  }
  
  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--cui-border-normal);
    background: var(--cui-bg-normal);
    width: 80vw;
    max-width: 538px;
    z-index: 1000;
  }
  
  .search > input {
    margin-right: 4px;
    z-index: 1000;
  }
  
  .inputSearch {
    width: 80vw;
    max-width: 538px;
    z-index: 10000;
  }
  
  .searchOverlay {
    top: 200px;
    display: flex;
    justify-content: center;
    gap: var(--cui-spacings-byte);
    z-index: 1000;
  }
  
  .searchResultsContainer {
    display: block;
    position: relative;
    background-color: var(--cui-bg-normal);
    right: 0;
    top: 8px;
    height: auto;
    z-index: 1000;
    border-radius: 16px;
    border: var(--cui-border-width-kilo) solid var(--cui-border-subtle);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
  }
  
  .seeAllResultsButtonWrapper {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
  }
  
  .closeContainer {
    display: block;
    z-index: 1001;
    padding: var(--cui-spacings-kilo);
    border-radius: var(--cui-border-radius-kilo);
    border: var(--cui-border-width-kilo) solid var(--cui-border-normal);
  }
  
  .close {
    display: flex;
  }
}
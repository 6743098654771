.container {
  display: flex;
  min-width: 132px;
  padding: var(--cui-spacings-kilo) 0;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--cui-spacings-byte);
  align-self: stretch;
  position: relative;
  cursor: pointer;
}

.container:hover {
  background: var(--cui-bg-subtle);
}

.title {
  font-size: 14px;
  text-decoration: underline;
  color: var(--cui-fg-normal);
  margin-top: var(--cui-spacings-byte);
}

.body {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: var(--cui-fg-subtle);
  font-size: 14px;
  margin-top: var(--cui-spacings-byte);
}

.button {
  padding: 6px 0px;
}

.image {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    display: flex;
    min-width: 132px;
    padding: var(--cui-spacings-kilo) var(--cui-spacings-giga);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cui-spacings-byte);
    flex: 1 0 0;
    align-self: stretch;
  }

  .title {
    font-size: 14px;
  }
  
  .body {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
  }
  .button {
    padding: 6px 0px;
  }
  
  .image {
    width: 24px;
    height: 24px;
  }  
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    display: flex;
    min-width: 132px;
    padding: var(--cui-spacings-kilo) var(--cui-spacings-giga);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cui-spacings-byte);
    flex: 1 0 0;
    align-self: stretch;
  }
  
  .body {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
  }

  .title {
    font-size: 16px;
  }

  .button {
    padding: 6px 0px;
  }
  
  .image {
    width: 24px;
    height: 24px;
  }  
}

@media screen and (min-width: 1280px) and (max-width: 1780px) {
  .container {
    display: flex;
    min-width: 132px;
    padding: var(--cui-spacings-kilo) var(--cui-spacings-giga);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cui-spacings-byte);
    flex: 1 0 0;
    align-self: stretch;
  }
  

  .title {
    font-size: 18px;
  }

  .body {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
  }

  .button {
    padding: 6px 0px;
  }
  
  .image {
    width: 24px;
    height: 24px;
  }  
}
@media screen and (min-width: 1780px) {
  .container {
    display: flex;
    min-width: 132px;
    padding: var(--cui-spacings-kilo) var(--cui-spacings-giga);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--cui-spacings-byte);
    flex: 1 0 0;
    align-self: stretch;
  }

  .title {
    font-size: 18px;
  }
  
  .body {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
  }
  .button {
    padding: 6px 0px;
  }
  
  .image {
    width: 24px;
    height: 24px;
  }  
}
.container {
  display: flex;
}

.link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.logo-name {
  display: flex;
  cursor: pointer;
}
.logo-name a {
  text-decoration: none;
  color: unset;
}

.logo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* Tablet styles */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    display: flex;
  }

  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .logo {
    width: 24px;
    height: 24px;
  }
}

/* Desktop styles */
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .logo {
    width: 32px;
    height: 32px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .logo {
    width: 32px;
    height: 32px;
  }
}

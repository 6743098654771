/* Mobile styles */
.container {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  margin: auto;
}

.footer {
  padding-top: 76px;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .footer {
    padding-top: 76px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .footer {
    padding: 100px 32px 32px 32px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1760px) {
  .footer {
    padding: 100px 32px 32px 32px;
  }
}

@media screen and (min-width: 1760px) {
  .footer {
    padding: 100px 32px 32px 32px;
  }
}
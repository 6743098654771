.container {
  display: flex;
  position: relative;
}

.search {
  display: none;
}

.search > input {
  margin-right: 4px;
}

.searchResultsDropdown {
  display: block;
  position: absolute;
  background-color: var(--cui-bg-normal);
  top: 60px;
  right: 0;
  height: auto;
  z-index: var(--cui-z-index-popover);
  border-radius: var(--cui-spacings-mega);
  border: var(--cui-border-width-kilo) solid var(--cui-border-subtle);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
  overflow: auto;
}

.seeAllResultsButtonWrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    display: flex;
    position: relative;
  }
  
  .search {
    display: none;
  }

  .searchIconWrapper {
    display: flex;
  } 
  
  .searchResultsDropdown {
    display: block;
    position: absolute;
    background-color: var(--cui-bg-normal);
    top: 60px;
    right: 0;
    height: auto;
    z-index: var(--cui-z-index-popover);
    border-radius: var(--cui-spacings-mega);
    border: var(--cui-border-width-kilo) solid var(--cui-border-subtle);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
    overflow: auto;
  }
  
  .seeAllResultsButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--cui-spacings-mega);
    margin-bottom: var(--cui-spacings-giga);
  }
}
@media screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    display: flex;
    position: relative;
  }
  
  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    padding: 0 var(--cui-spacings-giga) 0 0;
    border: var(--cui-border-width-kilo) solid var(--cui-border-normal);
    background: var(--cui-bg-normal);
    width: 400px;
    max-width: 538px;
  }
  
  .search > input {
    margin-right: 4px;
  }

  .inputSearch {
    width: 400px;
    max-width: 538px;
  }

  .searchIconWrapper {
    display: none;
  }
  
  .searchResultsDropdown {
    display: block;
    position: absolute;
    background-color: var(--cui-bg-normal);
    top: 60px;
    right: 0;
    height: auto;
    z-index: var(--cui-z-index-popover);
    border-radius: var(--cui-spacings-mega);
    border: var(--cui-border-width-kilo) solid var(--cui-border-subtle);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
    overflow: auto;
  }
  
  .seeAllResultsButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--cui-spacings-mega);
    margin-bottom: var(--cui-spacings-giga);
  }
}
@media screen and (min-width: 1280px) and (max-width: 1780px) {
  .container {
    display: flex;
    position: relative;
  }
  
  .searchIconWrapper {
    display: none;
  }

  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--cui-border-normal);
    background: var(--cui-bg-normal);
    width: 520px;
    max-width: 538px;
  }
  
  .search > input {
    margin-right: 4px;
  }
  
  .searchResultsDropdown {
    display: block;
    position: absolute;
    background-color: var(--cui-bg-normal);
    top: 60px;
    right: 0;
    height: auto;
    z-index: var(--cui-z-index-popover);
    border-radius: var(--cui-spacings-mega);
    border: var(--cui-border-width-kilo) solid var(--cui-border-subtle);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
    overflow: auto;
  }
  
  .inputSearch {
    width: 520px;
    max-width: 538px;
  }
  
  .seeAllResultsButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--cui-spacings-mega);
    margin-bottom: var(--cui-spacings-giga);
  }
}

@media screen and (min-width: 1780px) {
  .container {
    display: flex;
    position: relative;
  }
  
  .searchIconWrapper {
    display: none;
  }

  .inputSearch {
    width: 520px;
    max-width: 538px;
  }
  
  .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--cui-border-normal);
    background: var(--cui-bg-normal);
  }
  
  .search > input {
    margin-right: 4px;
  }
  
  .searchResultsDropdown {
    display: block;
    position: absolute;
    background-color: var(--cui-bg-normal);
    top: 60px;
    right: 0;
    height: auto;
    z-index: var(--cui-z-index-popover);
    border-radius: var(--cui-spacings-mega);
    border: var(--cui-border-width-kilo) solid var(--cui-border-subtle);
    background: var(--cui-bg-normal);
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10);
    overflow: auto;
  }
  
  .seeAllResultsButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--cui-spacings-mega);
    margin-bottom: var(--cui-spacings-giga);
  }
}
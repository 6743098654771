.container {
  display: flex;
  padding: 0 24px;
}

.articleBody {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--cui-fg-subtle);
}
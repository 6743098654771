.container {
  display: flex;
  padding: 56px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 24px 24px 0px 0px;
  background: var(--cui-bg-subtle);
}

.homeButton {
  display: flex;
  padding-right: 32px;
  align-items: center;
  gap: 12px;
}

.logo {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.logoName {
  display: flex;
  cursor: pointer;
}

.logoName a {
  text-decoration: none;
  color: unset;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.menuContainer {
  display: flex;
  max-width: 610px;
  padding-right: 56px;
  align-items: flex-start;
  align-content: flex-start;  
  gap: 40px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.column {
  display: flex;
  min-width: 120px;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 12px;
  flex: 1 0 0;
  cursor: pointer;
}

.legalNotesWrapper {
  display: flex;
  max-width: 980px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 24px 56px;
  padding: 56px 0 32px 0;
  flex: 1 0 0;
  margin: auto;
  flex-wrap: wrap;
}

.legalNotes {
  display: flex;
  max-width: 980px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 24px 56px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.legalNote {
  color: var(--cui-fg-subtle);
  cursor: pointer;
}

.socialLinks {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
}

.socialLink {
  cursor: pointer;
}

.disclaimerContainer {
  margin: auto;
  text-align: center;
  display: flex;
  color: var(--cui-bg-subtle);
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    display: flex;
    padding: 56px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 24px 24px 0 0;
  }

  .menuContainer {
    display: flex;
    max-width: 610px;
    padding-right: 56px;
    align-items: flex-start;
    align-content: flex-start;  
    gap: 40px 72px;
    flex: 1 0 0;
    flex-wrap: wrap;
  }
  
  .column {
    display: flex;
    min-width: 120px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }

}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .container {
    display: flex;
    padding: 56px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 24px;
  }

  .menuContainer {
    display: flex;
    max-width: 610px;
    padding-right: 56px;
    align-items: flex-start;
    align-content: flex-start;  
    gap: 40px;
    flex: 1 0 0;
    flex-wrap: wrap;
  }
  
  .column {
    display: flex;
    min-width: 120px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }

}

@media screen and (min-width: 1280px) and (max-width: 1760px) {
  .container {
    display: flex;
    padding: 56px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
    border-radius: 24px;
  }

  .menuContainer {
    display: flex;
    max-width: 610px;
    padding-right: 56px;
    align-items: flex-start;
    align-content: flex-start;  
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .column {
    display: flex;
    min-width: 120px;
    flex-direction: column;
    align-items: flex-start;
  }

}

@media screen and (min-width: 1760px) {
  .container {
    display: flex;
    padding: 56px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 24px;
  }

  .menuContainer {
    display: flex;
    max-width: 610px;
    padding-right: 56px;
    align-items: flex-start;
    align-content: flex-start;  
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .column {
    display: flex;
    min-width: 120px;
    flex-direction: column;
    align-items: flex-start;
  }

}
